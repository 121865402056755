import React, { useEffect } from 'react'
import { navigate } from "gatsby"

import { Link } from 'gatsby'

import SEO from "../components/SEO/SEO"
import Breadcrumb from '../components/Breadcrumb/Breadcrumb'
import InfoCard from '../components/InfoCard/InfoCard'
import RegisterForm from '../components/Register/RegisterForm'

import useAuth from '../hooks/useAuth'

export default function Register( { location } ) {

	const { isAuthenticated } = useAuth();

	useEffect( () => {
		if ( isAuthenticated ) {
			navigate( '/account' );
		}
	}, [ isAuthenticated ] );

	return (
		<>
		<SEO
			title="Register"
			pathname={ location.pathname } />
		<Breadcrumb pathname={ location.pathname } />
			<div className="wrapper">
				<InfoCard />

				<div className="register-form">
					<div className="register-form__container">
						<h1 className="register-form__heading">Register Account</h1>
						<p className="register-form__info">
							If you already have an account with us, please login at the <Link to="/login" className="register-form__login-link">login page</Link>.
						</p>

						<RegisterForm />
					</div>
				</div>
			</div>
		</>
	)
}